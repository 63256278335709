import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlueSky, LazyBottomCenter, LazyBottomLeft, LazyBottomRight, LazyCenterCenter, LazyCenterLeft, LazyCenterRight, LazyForgotPassword, LazyOpenEmail, LazyTopCenter, LazyTopLeft, LazyTopRight, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["BlueSky", LazyBlueSky],
["BottomCenter", LazyBottomCenter],
["BottomLeft", LazyBottomLeft],
["BottomRight", LazyBottomRight],
["CenterCenter", LazyCenterCenter],
["CenterLeft", LazyCenterLeft],
["CenterRight", LazyCenterRight],
["ForgotPassword", LazyForgotPassword],
["OpenEmail", LazyOpenEmail],
["TopCenter", LazyTopCenter],
["TopLeft", LazyTopLeft],
["TopRight", LazyTopRight],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
