import validate from "/home/forge/thelisting.app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/forge/thelisting.app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/forge/thelisting.app/middleware/auth.js"),
  guest: () => import("/home/forge/thelisting.app/middleware/guest.js"),
  onboarding: () => import("/home/forge/thelisting.app/middleware/onboarding.js")
}