export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.directive('img-error', {
        mounted(el) {
            el.onerror = () => {
                el.src = '/placeholder.png';
            };
        },

        updated(el) {
            el.onerror = () => {
                el.src = '/placeholder.png';
            };
        },
    })
})
