export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.directive('click-outside', {
        mounted(el, binding) {
            const eventHandler = (event) => {
                if (!el.contains(event.target)) {
                    binding.value(event);
                }
            };

            document.addEventListener('click', eventHandler);
            el._click_outside_event_handler = eventHandler;
        },

        unmounted(el) {
            document.removeEventListener('click', el._click_outside_event_handler);
            delete el._click_outside_event_handler;
        },
    });
})
