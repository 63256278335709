export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()
    const cookie = useCookie('token')

    const api = $fetch.create({
        baseURL: `${config.public.api_url}/api/`,

        onRequest({request, options, error}) {
            if (cookie.value) {
                const headers = options.headers ||= {}
                if (Array.isArray(headers)) {
                    headers.push(['Authorization', `Bearer ${cookie.value}`])
                } else if (headers instanceof Headers) {
                    headers.set('Authorization', `Bearer ${cookie.value}`)
                } else {
                    headers.Authorization = `Bearer ${cookie.value}`
                }
            }
        },

        async onResponseError({response}) {
            const status = response.status
            if (status !== 401) {
                if (status === 404) {
                    throw createError({
                        status: 404,
                        message: 'Resource not found.',
                        fatal: true,
                    })
                }

                useNuxtApp().$bus.$emit('flash', {
                    type: 'error',
                    title: response._data.message,
                    timeout: 5000,
                })

                throw new Error(response._data || 'An error occurred.')
            }
        }
    })

    return {
        provide: {
            api
        }
    }
})
